






































import Vue from 'vue';
import { ApiResource } from '@/types';
import { Token } from '@stripe/stripe-js';
import BankAccountForm from './BankAccountForm.vue';

export default Vue.extend({
  components: {
    BankAccountForm,
  },
  props: {
    actionIntent: {
      type: String,
      required: false,
      default: 'update',
    },
    buttonClass: {
      type: Array,
      required: false,
      default(): never[] {
        return [];
      },
    },
    account: {
      type: Object as () => ApiResource.Business.Account,
      required: true,
    },
    business: {
      type: Object as () => ApiResource.Business,
      required: true,
    },
  },
  data() {
    return {
      bankAccount: false,

      success: false,

      error: null,
      loading: false,
    };
  },
  methods: {
    async handleBankAccountToken(token: Token): Promise<void> {
      this.error = null;
      this.loading = true;
      this.bankAccount = false;

      await this.$store.dispatch('business/ChangeExternalAccount', {
        BUSINESS_ID: this.business.id,
        form: {
          token: token.id,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;

      if (!this.error) this.success = true;
    },
  },
});
