




































































































































































































































































































import Vue from 'vue';
import {
  Stripe,
  StripeElements,
  StripeIbanElement,
  StripeIbanElementChangeEvent,
  Token,
  CreateTokenIbanData,
  StripeError,
  StripeElementType,
  StripeIbanElementOptions,
  CreateTokenBankAccountData,
} from '@stripe/stripe-js';
import { SelectOption } from '@/types';

interface BankAccountCountry {
  [index: string]: CountryCode[],
}
type CountryCode = string;
type CurrencyCode = string;

const AUD: CountryCode[] = ['AU'];
const CAD: CountryCode[] = ['CA'];
const EUR: CountryCode[] = [
  'AT', 'BE', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'LV', 'LT',
  'LU', 'NL', 'NO', 'PL', 'PT', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB',
];
const DKK: CountryCode[] = ['DK'];
const GBP: CountryCode[] = ['GB'];
const HKD: CountryCode[] = ['HK'];
// const JPY: CountryCode[] = ['JP'];
const MXN: CountryCode[] = ['MX'];
const MYR: CountryCode[] = ['MY'];
const NOK: CountryCode[] = ['NO'];
const NZD: CountryCode[] = ['NZ'];
const PLN: CountryCode[] = ['PL'];
const SEK: CountryCode[] = ['SE'];
const SGD: CountryCode[] = ['SG'];
const USD: CountryCode[] = ['US'];
const CHF: CountryCode[] = ['CH'];

const EU: {
  [index: string]: CurrencyCode[],
} = {
  EUR, CHF, DKK, GBP, NOK, SEK, USD,
};

const isEU: CountryCode[] = [
  'AT', 'BE', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'IE',
  'IT', 'LT', 'LU', 'LV', 'NL', 'NO', 'PT', 'SE', 'SI', 'SK',
];

const MATRIX: {
  [index: string]: BankAccountCountry,
} = {
  AU: { AUD },
  CA: {
    CAD,
    USD: ['CA', 'US'],
  },
  CH: {
    CHF, EUR, DKK, GBP, NOK, SEK, USD,
  },
  HK: { HKD },
  // JP: { JPY },
  MX: { MXN },
  MY: { MYR },
  NZ: { NZD },
  PL: {
    PLN,
    ...EU,
  },
  SG: { SGD },
  US: { USD },
};

const isIBAN = [
  'AT', 'BE', 'DK', 'EE', 'FI', 'FR', 'DE', 'GI', 'IE', 'IT', 'LT',
  'LU', 'LV', 'NL', 'NO', 'PL', 'PT', 'SK', 'SI', 'ES', 'SE', 'CH',
];

const BANK_ACCOUNT_FORMATS: {
  [index: string]: {
    [index: string]: [number, number, string?, string?], // min, max, placeholder, pattern
  },
} = {
  AU: {
    bsb: [6, 6, '123456', '.{6,6}'], // 6 characters
    account_number: [6, 9, '12345678', '.{6,9}'], // 6–9 characters
  },
  // BR: {
  //   bank_code: [3, 3, '123', '.{3,3}'], // 3 characters
  //   branch_code: [4, 5, '4567', '.{4,5}'], // 4 characters, with 1 optional check digit
  //   account_number: [1, 99, '', '.{0,99}'], // Format varies by bank
  // },
  CA: { // routing_number: "transit_number""institution_number"
    transit_number: [5, 5, '12345', '.{5,5}'],
    institution_number: [3, 3, '678', '.{3,3}'],
    account_number: [1, 99, '', '.{1,99}'], // Format varies by bank
  },
  HK: { // routing_number: "clearing_code"-"branch_code"
    clearing_code: [3, 3, '123', '.{3,3}'],
    branch_code: [3, 3, '456', '.{3,3}'],
    account_number: [6, 9, '123456-789', '.{6,9}'],
  },
  // JP: {},
  // MY: {
  //   account_number: [5, 17, '1234567890', '.{5,17}'], // 5-17 digits, format varies by bank
  // },
  MX: {
    CLABE: [18, 18, '123456789012345678', '.{18,18}'],
  },
  NZ: {
    account_number: [15, 16, 'AABBBB3456789YZZ', '.{15,16}'],
  },
  SG: { // routing_number: "bank_code"-"branch_code"
    bank_code: [4, 4, '1234', '.{4,4}'],
    branch_code: [3, 3, '567', '.{3,3}'],
    account_number: [6, 12, '123456789012', '.{6,12}'],
  },
  GB: {
    sort_code: [6, 8, '12-34-56', '.{6,8}'],
    account_number: [8, 8, '01234567', '.{8,8}'],
  },
  US: {
    routing_number: [9, 9, '111000000', '.{9,9}'],
    account_number: [1, 99, '', '.{1,99}'],
  },
};

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String as () => CountryCode,
      required: true,
      validator: (cc): boolean => cc.length === 2,
    },
  },
  data() {
    return {
      bankAccount: {
        currency: 'EUR' as string,
        country: 'DE' as string | CountryCode,

        name: '', // account_holder.name
        type: null as 'individual'|'company'|null,

        // routing_number parts
        routing_number: '',
        bsb: '',
        sort_code: '',

        bank_code: '',
        transit_number: '',
        clearing_code: '',

        branch_code: '',
        institution_number: '',
        // account_number, not IBAN
        account_number: '',
        CLABE: '',

        bankName: null as null | string,
        error: null as any,
      },

      error: null as null | Error | StripeError,
      loading: false,

      stripe: null as null | Stripe,
      stripeElements: null as null | StripeElements,
      stripeIbanElement: null as null | StripeIbanElement,
    };
  },
  computed: {
    MATRIX(): {[index: string]: BankAccountCountry} {
      const matrix = MATRIX;

      isEU.forEach((cc) => {
        matrix[cc] = EU;
      });

      return matrix;
    },
    accountCountryMatrix(): BankAccountCountry {
      return this.MATRIX[this.countryCode];
    },
    accountCountryCurrencies(): string[] {
      return Object.keys(this.accountCountryMatrix);
    },
    defaultAccountCountryCurrency(): string {
      return this.accountCountryCurrencies[0];
    },
    bankAccountCountries(): CountryCode[] {
      return this.accountCountryMatrix[this.bankAccount.currency] || [];
    },
    defaultBankAccountCountry(): CountryCode {
      if (this.bankAccountCountries.includes(this.countryCode)) return this.countryCode;
      return this.bankAccountCountries[0];
    },

    bankAccountCurrencyOptions(): SelectOption[] {
      const opts: SelectOption[] = [];

      this.accountCountryCurrencies.forEach((value) => {
        opts.push({
          value,
          text: `${value} - ${this.$t(`currency.${value}`)}`,
        });
      });

      return opts;
    },
    bankAccountCountryOptions(): SelectOption[] {
      const opts: SelectOption[] = [];

      this.bankAccountCountries.forEach((value) => {
        opts.push({
          value,
          text: this.$t(`country.${value}`),
        });
      });

      return opts.sort((a, b) => {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      });
    },
    bankAccountFormat(): {[index: string]: [number, number, string?, string?]} | 'IBAN' {
      if (isIBAN.includes(this.bankAccount.country)) return 'IBAN';
      return BANK_ACCOUNT_FORMATS[this.bankAccount.country];
    },
  },
  watch: {
    '$props.countryCode': {
      immediate: true,
      handler(): void {
        this.bankAccount.currency = this.defaultAccountCountryCurrency;
      },
    },
    'bankAccount.currency': {
      immediate: true,
      handler(): void {
        this.bankAccount.country = this.defaultBankAccountCountry;
      },
    },
    'bankAccount.country': {
      immediate: true,
      handler(to): void {
        if (this.bankAccountFormat === 'IBAN') {
          if (!this.stripeIbanElement) return;

          this.stripeIbanElement.update({
            placeholderCountry: to,
          });
        }
      },
    },
  },
  created() {
    this.createStripeIbanElement();
  },
  methods: {
    async createStripeIbanElement(): Promise<void> {
      const stripe = await this.$stripe().catch((e) => { this.error = e; });

      if (!stripe) return;

      this.stripe = stripe;
      this.stripeElements = this.stripe.elements();

      const elementType: StripeElementType = 'iban';
      const options: StripeIbanElementOptions = {
        supportedCountries: ['SEPA'],
        placeholderCountry: this.bankAccount.currency,
        iconStyle: 'solid', // "default"
        hideIcon: false,
        disabled: false,
        style: {
          base: {
            color: '#363636',
            fontFamily: 'BlinkMacSystemFont,-apple-system,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue","Helvetica","Arial",sans-serif',
            fontSize: '20px',
            fontSmoothing: 'auto',
            // fontStyle: null
            '::placeholder': {
              color: 'rgba(54,54,54,0.3)',
            },
            iconColor: '#04a96a',
            // lineHeight: '1.5',
            letterSpacing: 'normal',
            // textDecoration: null
            textShadow: 'none',
            textTransform: 'none',
          },
        },
        classes: {
          base: 'stripe-element',
          complete: 'is-success',
          // emtpy: 'stripe-element-empty',
          focus: 'is-focussed',
          invalid: 'is-danger',
          // webkitAutofill: 'stripe-element-webkit-autofill',
        },
      };

      this.stripeIbanElement = this.stripeElements.create(elementType, options);

      this.stripeIbanElement?.mount('#add-bank-account\\.field\\.iban');
      this.stripeIbanElement?.on('change', this.onStripeIbanElementChanged);
    },
    onStripeIbanElementChanged(event: StripeIbanElementChangeEvent): void {
      this.bankAccount.bankName = event.bankName;
      if (event.country) this.bankAccount.country = event.country;
      this.bankAccount.error = event.error;
    },
    async save(): Promise<void> {
      this.error = null;
      this.loading = true;

      let token;

      if (this.bankAccountFormat === 'IBAN') token = await this.tokenizeIban();
      else token = await this.tokenizeBankAccount();

      this.loading = false;

      if (!token) return;

      this.$emit('bankaccount-tokenized', token);
    },
    async tokenizeIban(): Promise<Token | undefined> {
      if (!this.stripe || !this.stripeIbanElement || !this.bankAccount.type) return undefined;

      const element: StripeIbanElement = this.stripeIbanElement;
      const data: CreateTokenIbanData = {
        currency: this.bankAccount.currency,
        account_holder_name: this.bankAccount.name,
        account_holder_type: this.bankAccount.type,
      };

      const { error, token } = await this.stripe.createToken(element, data);

      if (error) {
        this.error = error;
        return undefined;
      }

      return token;
    },
    async tokenizeBankAccount(): Promise<Token | undefined> {
      if (!this.stripe || !this.stripeIbanElement || !this.bankAccount.type) return undefined;

      const tokenType = 'bank_account';
      const data: CreateTokenBankAccountData = {
        country: this.bankAccount.country,
        currency: this.bankAccount.currency,
        account_holder_name: this.bankAccount.name,
        account_holder_type: this.bankAccount.type,
        routing_number: this.assembleRoutingNumber(),
        account_number: this.bankAccount.CLABE || this.bankAccount.account_number,
      };

      const { error, token } = await this.stripe.createToken(tokenType, data);

      if (error) {
        this.error = error;
        return undefined;
      }

      return token;
    },
    assembleRoutingNumber(): string {
      let routingNumber = '';

      switch (this.bankAccount.country) {
        case 'AU':
          routingNumber = this.bankAccount.bsb;
          break;
        case 'CA':
          routingNumber = [
            this.bankAccount.transit_number,
            this.bankAccount.institution_number,
          ].join('');
          break;
        case 'HK':
          routingNumber = [
            this.bankAccount.clearing_code,
            this.bankAccount.branch_code,
          ].join('-');
          break;
        case 'SG':
          routingNumber = [
            this.bankAccount.bank_code,
            this.bankAccount.branch_code,
          ].join('-');
          break;
        case 'GB':
          routingNumber = this.bankAccount.sort_code;
          break;
        case 'US':
          routingNumber = this.bankAccount.routing_number;
          break;
        default:
          break;
      }

      return routingNumber;
    },
  },
});
